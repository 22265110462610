<template>
  <div class="statuses-page">
    <offer-block></offer-block>
    <statuses-block></statuses-block>
    <benefits-block></benefits-block>
    <benefits-status-block></benefits-status-block>
    <community-block></community-block>

<!--    <mission-block ref="missionBlock"></mission-block>-->
<!--    <multi-chain-block></multi-chain-block>-->
<!--    <nft-craft-block></nft-craft-block>-->
<!--    <stacking-block></stacking-block>-->
<!--    <metrics-block></metrics-block>-->
<!--    <status-cards-block></status-cards-block>-->
<!--    <how-to-get-panel class="bottom-panel" :class="{'-active': isShowPanel}"></how-to-get-panel>-->
  </div>
</template>

<script>
import OfferBlock from "./snippets/OfferBlock";
import MissionBlock from "./snippets/MissionBlock";
import StatusesBlock from "./snippets/StatusesBlock";
import BenefitsBlock from "./snippets/BenefitsBlock";
import AllocationsBlock from "./snippets/AllocationsBlock";
import MultiChainBlock from "./snippets/MultiChainBlock";
import NftCraftBlock from "./snippets/NftCraftBlock";
import StackingBlock from "./snippets/StackingBlock";
import StatusCardsBlock from "./snippets/StatusCardsBlock";
import MetricsBlock from "./snippets/MetricsBlock";
import BenefitsStatusBlock from "./snippets/BenefitsStatusBlock";
import WithUsBlock from "./snippets/WithUsBlock";
import CommunityBlock from "./snippets/CommunityBlock";
import HowToGetPanel from "./snippets/HowToGetPanel";

export default {
    name: 'statuses-page',
    components: {
        'how-to-get-panel': HowToGetPanel,
        'allocations-block': AllocationsBlock,
        'benefits-block': BenefitsBlock,
        'statuses-block': StatusesBlock,
        'mission-block': MissionBlock,
        'multi-chain-block': MultiChainBlock,
        'offer-block': OfferBlock,
        'stacking-block': StackingBlock,
        'status-cards-block': StatusCardsBlock,
        'with-us-block': WithUsBlock,
        'community-block' :CommunityBlock,
        'benefits-status-block' :BenefitsStatusBlock,
        'metrics-block' :MetricsBlock,
        'nft-craft-block': NftCraftBlock
    },
    data() {
      return {
          isShowPanel: false,
      }
    },
    mounted() {
      // scroll click
      const userSelection = document.getElementsByClassName('scroll-click');

      for(let n = 0; n < userSelection.length; n++) {
        (function(index) {
          userSelection[index].addEventListener("click", function(e) {
            e.preventDefault();
            let scrollElement = document.getElementsByClassName('statuses-block')[0];

            let bodyRect = document.body.getBoundingClientRect(),
                elemRect = scrollElement.getBoundingClientRect(),
                pos = elemRect.top - bodyRect.top - 60;
            let currentPos = window.scrollY;
            let start = null;
            let time = 500;
            pos = +pos, time = +time;
            window.requestAnimationFrame(function step(currentTime) {
              start = !start ? currentTime : start;
              let progress = currentTime - start;
              if (currentPos < pos) {
                window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
              } else {
                window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
              }
              if (progress < time) {
                window.requestAnimationFrame(step);
              } else {
                window.scrollTo(0, pos);
              }
            });
          })
        })(n);
      }
    },
    beforeDestroy() {

    }
}
</script>

<style lang="less">
.statuses-page {
  background: #031337;
  color: #FFF;
  .container {
    width: 1180px;
    margin: 0 auto;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 1439px) {
    .container {
      width: 850px;
    }
  }
  @media screen and (max-width: 1023px) {
    .container {
      width: 670px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      width: 100%;
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 429px) {
    .container {
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>