<template>
    <div class="how-to-get-panel">
        <div class="wrapper">
            <img class="image" :src="require(`../images/statuses/cards.webp`)" alt="">

            <w-button class="button" @click="openModal" type="promo" size="small">
                {{ $i18n.get(`How to get TRUE Status cards`) }}
            </w-button>

            <svg width="1440" height="60" viewBox="0 0 1440 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_6221" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="60">
                    <rect width="1440" height="60" fill="#102D65"/>
                </mask>
                <g mask="url(#mask0_1_6221)">
                    <g filter="url(#filter0_f_1_6221)">
                        <ellipse cx="833.5" cy="34" rx="145.5" ry="50" fill="#FF55BB" fill-opacity="0.6"/>
                    </g>
                    <g filter="url(#filter1_f_1_6221)">
                        <ellipse cx="655" cy="36" rx="130" ry="50" fill="#F1965A" fill-opacity="0.8"/>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_f_1_6221" x="538" y="-166" width="591" height="400" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_1_6221"/>
                    </filter>
                    <filter id="filter1_f_1_6221" x="375" y="-164" width="560" height="400" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_1_6221"/>
                    </filter>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'how-to-get-panel',
    methods: {
        openModal() {
            this.$modal.show(() => import(/* webpackChunkName: 'statuses' */ '../../modals/GetStatusCards'), {}, {
                name: 'get-status-cards',
                width: 580,
            })
        },
    }
}
</script>

<style lang="less">
.how-to-get-panel {
    width: 100%;
    height: 60px;
    background-color: #102D65;
    filter: blur(0);

    > .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        > .image {
            position: relative;
            width: 97px;
            height: 78px;
            margin-top: -18px;
            margin-right: 8px;
            z-index: 2;
        }

        > .button {
            position: relative;
            z-index: 2;
        }

        > svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
}

@media (max-width: 767px) {
    .how-to-get-panel {
        > .wrapper {
            > .image {
                position: absolute;
                margin-right: 0;
                left: calc(50% - 97px/2);
                bottom: 10px;
            }
        }
    }
}
</style>
