<template>
  <div class="benefits-block">
    <div class="container">
      <div class="benefits-rows">
        <div class="row">
          <div class="title mobile">
            {{ $i18n.get(`Staking TFT`) }}
          </div>
          <div class="image">
            <img :src="require('../images/statuses/staking-image.png')" :alt="$i18n.get(`Staking TFT`)">
          </div>
          <div class="content">
            <div class="title">
              {{ $i18n.get(`Staking TFT`) }}
            </div>
            <p class="text">
              Stake TFT and receive a reward every day.<br> Available staking period depends on the Status card (30-360 days). The rate is adjustable and depends on the staking period, Status card, the amount, and the total amount of the staked funds.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="title mobile">
            {{ $i18n.get(`NFT drops pre-sale`) }}
          </div>
          <div class="content">
            <div class="title">
              {{ $i18n.get(`NFT drops pre-sale`) }}
            </div>
            <p class="text">
              Statuses give access to the pre-sale of the  future NFT drops. During pre-sale, you can buy a different amount of NFTs depending on the status (from 1 to 100 per person).
            </p>
          </div>
          <div class="image">
            <img :src="require('../images/statuses/nft-drop-image.png')" :alt="$i18n.get(`NFT drops pre-sale`)">
          </div>
        </div>

        <div class="row">
          <div class="title mobile">
            {{ $i18n.get(`NFT craft`) }}
          </div>
          <div class="image">
            <img :src="require('../images/statuses/nft-craft-image.png')" :alt="$i18n.get(`NFT craft`)">
          </div>
          <div class="content">
            <div class="title">
              {{ $i18n.get(`NFT craft`) }}
            </div>
            <p class="text">
              You can craft a major status NFT from several minor status NFTs at a ratio of 1:9. A maximum number of tier-1 NFTs is limited.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="title mobile">
            {{ $i18n.get(`Governance`) }}
          </div>
          <div class="content">
            <div class="title">
              {{ $i18n.get(`Governance`) }}
            </div>
            <p class="text">
              A management structure where the community makes decisions on its further development by voting. The main financial indicators of the system functioning are available in a convenient graphical format using BI tools.
            </p>
          </div>
          <div class="quote">
            <img :src="require('../images/statuses/quote-image.png')" :alt="$i18n.get(`Governance`)">
            <div class="text">
              «Feedback is the key to the success of any project. You need to be able to hear your community and build a project hand in hand. And at some point, it's no longer you who build the company, but the community manages this task on its own.»
            </div>
            <div class="author">
              <div class="avatar"></div>
              <div class="title">Dan, co-founder<br> & CEO of TRUE</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    name: 'benefits-block',
    data() {
        return {
            benefitsCards: [
                {
                    logo: 'logo-tl.svg',
                    tag: 'Upcoming',
                    image: 'drops1.webp',
                    name: 'Victoria Wild West',
                    description: 'The first iGaming project with in-game NFT distribution. A part of NFTs will be sold in mystery boxes. NFT owners can collect albums and craft them into higher value NFTs.',
                },
                {
                    logo: 'logo-tf.svg',
                    tag: 'Upcoming',
                    image: 'drops3.webp',
                    name: 'NFT Jackpot 1 BTC',
                    description: 'The unique NFT drop from the best iGaming project of 2021. 1 BTC Jackpot is embed in one of the NFTs. The rest can be used on the platform to receive real bonuses.',
                },
                {
                    logo: 'logo-true.svg',
                    tag: 'Upcoming',
                    image: 'drops4.webp',
                    name: 'Flip’s Star',
                    description: 'Our own lottery with NFT tickets to participate. During pre-sale the tickets will be sold at an exclusive price.',
                },
                {
                    tag: 'NDA',
                    image: 'drops2.webp',
                    name: 'MMA Fighters cards',
                    description: 'The collection of fighters cards with their personal characteristics and autographs. An original in-house Play-2-Earn game: virtual MMA fights.',
                },
                {
                    logo: 'logo-zept.svg',
                    tag: 'Upcoming',
                    image: 'drops5.webp',
                    name: 'Om Nom',
                    description: 'Collectible NFTs from the world-famous mobile game developer. Part of the NFTs can be exchanged for real items.',
                },
            ]
        }
    }
}
</script>

<style lang="less">
.benefits-block {
  background: #031337;
  margin-bottom: 130px;
  padding-top: 30px;

  > .container {
    width: var(--main-width-full);
    margin: 0 auto;

    > .benefits-rows {
      display: flex;
      flex-direction: column;
      > .row {
        display: flex;
        align-items: center;
        margin-top: -30px;
        &:nth-child(even) {
          justify-content: flex-end;
        }
        > .image {
          > img {
            width: 610px;
          }
        }
        > .content {
          width: 518px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 18px;
          > .title {
            position: relative;
            font-size: 40px;
            font-weight: 800;
            line-height: 42px;
            padding-left: 28px;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 2px;
              height: 40px;
              background: linear-gradient(180deg, #4F5C87 0%, rgba(79, 92, 135, 0.3) 100%);
            }
          }
          > p {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            padding-left: 28px;
          }
        }

        > .quote {
          position: relative;
          width: 622px;
          height: 610px;
          background-image: url("../images/statuses/bg-quote.png");
          background-position: top right;
          background-size: contain;
          background-repeat: no-repeat;
          > img {
            position: relative;
            left: -12px;
            z-index: 2;
            margin-top: 66px;
            width: 100%;
          }
          > .text {
            position: absolute;
            left: 28px;
            top: 220px;
            width: 560px;
            height: 176px;
            padding: 32px 30px 32px 34px;
            background: #FFFFFF0D;
            backdrop-filter: blur(55px);
            border-radius: 30px 30px 0 30px;
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 28px;
            color: #FFFFFFB2;
          }
          > .author {
            position: absolute;
            right: 0;
            top: 381px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 13px;
            background: #FFFFFF0D;
            backdrop-filter: blur(55px);
            border-radius: 0 20px 20px 45px;
            padding: 12px 18px 12px 12px;
            box-shadow: 0px -11px 30px 0px #00000024;
            > .avatar {
              background-image: url("../images/statuses/dan.png");
              background-size: cover;
              width: 70px;
              height: 70px;
              border-radius: 50%;
              display: flex;
            }
            > .title {
              font-size: 15px;
              font-weight: 500;
              line-height: 26px;
              color: #FFFFFFB2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .benefits-block {
    margin-bottom: 100px;
    padding-top: 30px;

    > .container {
      > .benefits-rows {
        > .row {
          justify-content: space-between;
          &:nth-child(4) {
            gap: 30px;
            > .content {
              width: 366px;
            }
          }
          > .image {
            > img {
              display: block;
              width: 450px;
            }
          }
          > .content {
            width: 386px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 14px;
            > .title {
              position: relative;
              font-size: 32px;
              line-height: 42px;
              padding-left: 28px;
              &:before {
                height: 40px;
              }
            }
            > p {
              font-size: 16px;
              line-height: 28px;
              padding-left: 28px;
            }
          }

          > .quote {
            width: 450px;
            height: 450px;
            > img {
              left: -17px;
              z-index: 2;
              margin-top: 40px;
              width: 100%;
            }
            > .text {
              left: 21px;
              top: 152px;
              width: 409px;
              height: 168px;
              padding: 24px 26px 24px 24px;
              border-radius: 30px 30px 0 30px;
              font-size: 14px;
              line-height: 24px;
            }
            > .author {
              right: 0;
              top: 307px;
              gap: 13px;
              border-radius: 0 20px 20px 45px;
              padding: 12px 18px 12px 12px;
              > .avatar {
                width: 60px;
                height: 60px;
              }
              > .title {
                font-size: 13px;
                font-weight: 500;
                line-height: 22px;
                color: #FFFFFFB2;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .benefits-block {
    margin-bottom: 93px;
    padding-top: 20px;

    > .container {
      > .benefits-rows {
        > .row {
          justify-content: space-between;
          margin-top: -20px;
          &:nth-child(4) {
            gap: 30px;
            > .content {
              width: 298px;
            }
          }
          > .image {
            > img {
              display: block;
              width: 330px;
            }
          }
          > .content {
            width: 338px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            > .title {
              position: relative;
              font-size: 24px;
              line-height: 42px;
              padding-left: 18px;
              &:before {
                height: 32px;
              }
            }
            > p {
              padding-left: 18px;
              font-size: 14px;
              line-height: 26px;
            }
          }

          > .quote {
            width: 330px;
            height: 330px;
            > img {
              left: -24px;
              z-index: 2;
              margin-top: 0;
              width: 100%;
            }
            > .text {
              position: absolute;
              left: 7px;
              top: 81px;
              width: 306px;
              height: 164px;
              padding: 22px 24px 22px 22px;
              border-radius: 20px 20px 0 20px;
              font-size: 12px;
              line-height: 20px;
            }
            > .author {
              position: absolute;
              right: 0;
              top: 232px;
              gap: 10px;
              border-radius: 0 16px 16px 36px;
              padding: 12px 18px 12px 12px;
              > .avatar {
                width: 50px;
                height: 50px;
              }
              > .title {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .benefits-block {
    margin-bottom: 70px;
    padding-top: 0;

    > .container {
      > .benefits-rows {
        gap: 50px;
        > .row {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0;
          gap: 0;
          &:nth-child(4) {
            gap: 0;
            > .content {
              width: 100%;
            }
          }
          > .title.mobile {
            order: 1;
            display: block;
            position: relative;
            font-size: 24px;
            font-weight: 800;
            line-height: 36px;
            padding-left: 39px;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 2px;
              height: 32px;
              background: linear-gradient(180deg, #4F5C87 0%, rgba(79, 92, 135, 0.3) 100%);
            }
          }
          > .image {
            order: 2;
            > img {
              display: block;
              width: 100%;
            }
          }
          > .content {
            order: 3;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            > .title {
              display: none;
            }
            > p {
              padding-left: 17px;
              padding-right: 15px;
              font-size: 14px;
              line-height: 26px;
            }
          }

          > .quote {
            width: 100%;
            height: 390px;
            order: 2;
            > img {
              width: 390px;
              left: 0;
              z-index: 2;
              margin-top: 0;
            }
            > .text {
              position: absolute;
              left: 0;
              top: 109px;
              width: 100%;
              height: 150px;
              padding: 20px 25px 20px 25px;
              border-radius: 26px 26px 0 26px;
              font-size: 13px;
              line-height: 22px;
            }
            > .author {
              position: absolute;
              right: 0;
              top: 249px;
              gap: 10px;
              width: 60%;
              border-radius: 0 20px 20px 40px;
              padding: 14px 20px 14px 16px;
              justify-content: flex-start;
              > .avatar {
                width: 50px;
                height: 50px;
              }
              > .title {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 429px) {
  .benefits-block {
    margin-bottom: 60px;
    padding-top: 0;

    > .container {
      > .benefits-rows {
        gap: 40px;
        > .row {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0;
          gap: 0;
          &:nth-child(4) {
            gap: 0;
            > .content {
              width: 100%;
            }
          }
          > .title.mobile {
            order: 1;
            display: block;
            position: relative;
            font-size: 18px;
            font-weight: 800;
            line-height: 27px;
            padding-left: 18px;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 2px;
              height: 24px;
              background: linear-gradient(180deg, #4F5C87 0%, rgba(79, 92, 135, 0.3) 100%);
            }
          }
          > .image {
            order: 2;
            > img {
              display: block;
              width: 100%;
            }
          }
          > .content {
            order: 3;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            > .title {
              display: none;
            }
            > p {
              padding-left: 20px;
              padding-right: 15px;
              font-size: 12px;
              line-height: 22px;
            }
          }

          > .quote {
            width: 100%;
            height: 290px;
            order: 2;
            > img {
              width: 290px;
              left: 0;
              z-index: 2;
              margin-top: 6px;
            }
            > .text {
              position: absolute;
              left: 0;
              top: 81px;
              width: 100%;
              height: 138px;
              padding: 15px;
              border-radius: 20px 20px 0 20px;
              font-size: 11px;
              line-height: 18px;
            }
            > .author {
              position: absolute;
              right: 0;
              top: 208px;
              gap: 10px;
              width: 60%;
              border-radius: 0 16px 16px 36px;
              padding: 10px 15px 10px 12px;
              justify-content: flex-start;
              > .avatar {
                width: 40px;
                height: 40px;
              }
              > .title {
                font-size: 11px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
