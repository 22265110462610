<template>
    <div class="statuses-block">
      <div class="container">
        <div class="title">
          <div>{{ $i18n.get(`Status cards`) }}</div>
          <div class="text">
            <span>{{ $i18n.get(`the key`) }} <img :src="require('../images/statuses/key-3d-img.png')"> {{ $i18n.get(`to all the features of our platform`) }}</span>
          </div>
        </div>
        <div class="cards">
          <router-link :to="{name: 'nft', params: { id: 'bronze' }}" class="card -bronze">
            <div class="image">
              <img :src="require('../images/statuses/Bronze.png')" alt="Bronze status">
              <div class="tag">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99996 0C2.89539 0 1.99996 0.89543 1.99996 2V6C1.99996 7.10457 2.89539 8 3.99996 8H7.99995C9.10452 8 9.99995 7.10457 9.99995 6V2C9.99995 0.89543 9.10452 0 7.99995 0H3.99996ZM0.99995 3.00001C0.99995 2.70654 1.06316 2.42784 1.1767 2.17676C0.482807 2.49059 0 3.18891 0 3.99999V7.99999C0 9.10456 0.89543 9.99999 2 9.99999H6C6.8111 9.99999 7.50943 9.51716 7.82325 8.82323C7.57216 8.93679 7.29344 9.00001 6.99995 9.00001H2.99995C1.89538 9.00001 0.99995 8.10458 0.99995 7.00001V3.00001Z" fill="white"/>
                </svg>
                200K
              </div>
            </div>

            <div class="text">
              <div class="title">
                {{ $i18n.get(`Bronze True Status card`) }}
              </div>

              <div class="market">
                TRUE Official
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72864 1.46324C7.40007 0.690472 8.59993 0.690473 9.27136 1.46324L9.43946 1.65672C9.82056 2.09533 10.4008 2.30653 10.9747 2.2155L11.2278 2.17534C12.2389 2.01495 13.158 2.7862 13.1757 3.80976L13.1801 4.06603C13.1901 4.64699 13.4988 5.18177 13.997 5.48091L14.2167 5.61286C15.0943 6.1399 15.3027 7.32153 14.6582 8.11695L14.4969 8.3161C14.1311 8.76756 14.0239 9.37569 14.2132 9.92503L14.2967 10.1674C14.6302 11.1352 14.0303 12.1743 13.0253 12.3694L12.7737 12.4183C12.2033 12.529 11.7303 12.9259 11.5222 13.4684L11.4304 13.7077C11.0638 14.6635 9.93626 15.0739 9.04102 14.5774L8.81688 14.4531C8.30875 14.1712 7.69125 14.1712 7.18312 14.4531L6.95898 14.5774C6.06374 15.0739 4.93625 14.6635 4.56961 13.7077L4.47782 13.4684C4.26972 12.9259 3.79668 12.529 3.22629 12.4183L2.97468 12.3694C1.96973 12.1743 1.3698 11.1352 1.70332 10.1674L1.78683 9.92503C1.97613 9.37568 1.8689 8.76756 1.50313 8.3161L1.34178 8.11694C0.697344 7.32153 0.905697 6.1399 1.78332 5.61286L2.00305 5.48091C2.50117 5.18177 2.80992 4.64699 2.81993 4.06603L2.82434 3.80976C2.84196 2.7862 3.7611 2.01495 4.77217 2.17534L5.02531 2.2155C5.59918 2.30653 6.17944 2.09533 6.56054 1.65672L6.72864 1.46324Z" fill="#255CE7"/>
                  <path d="M5.47656 7.57874L7.16077 9.26295L10.5292 5.89453" stroke="white" stroke-width="1.26316"/>
                </svg>
              </div>

              <div class="price">
                10 USDT
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'nft', params: { id: 'silver' }}" class="card -silver">
            <div class="image">
              <img :src="require('../images/statuses/Silver.png')" alt="Silver status">
              <div class="tag">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99996 0C2.89539 0 1.99996 0.89543 1.99996 2V6C1.99996 7.10457 2.89539 8 3.99996 8H7.99995C9.10452 8 9.99995 7.10457 9.99995 6V2C9.99995 0.89543 9.10452 0 7.99995 0H3.99996ZM0.99995 3.00001C0.99995 2.70654 1.06316 2.42784 1.1767 2.17676C0.482807 2.49059 0 3.18891 0 3.99999V7.99999C0 9.10456 0.89543 9.99999 2 9.99999H6C6.8111 9.99999 7.50943 9.51716 7.82325 8.82323C7.57216 8.93679 7.29344 9.00001 6.99995 9.00001H2.99995C1.89538 9.00001 0.99995 8.10458 0.99995 7.00001V3.00001Z" fill="white"/>
                </svg>
                10K
              </div>
            </div>

            <div class="text">
              <div class="title">
                {{ $i18n.get(`Silver True Status card`) }}
              </div>

              <div class="market">
                TRUE Official
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72864 1.46324C7.40007 0.690472 8.59993 0.690473 9.27136 1.46324L9.43946 1.65672C9.82056 2.09533 10.4008 2.30653 10.9747 2.2155L11.2278 2.17534C12.2389 2.01495 13.158 2.7862 13.1757 3.80976L13.1801 4.06603C13.1901 4.64699 13.4988 5.18177 13.997 5.48091L14.2167 5.61286C15.0943 6.1399 15.3027 7.32153 14.6582 8.11695L14.4969 8.3161C14.1311 8.76756 14.0239 9.37569 14.2132 9.92503L14.2967 10.1674C14.6302 11.1352 14.0303 12.1743 13.0253 12.3694L12.7737 12.4183C12.2033 12.529 11.7303 12.9259 11.5222 13.4684L11.4304 13.7077C11.0638 14.6635 9.93626 15.0739 9.04102 14.5774L8.81688 14.4531C8.30875 14.1712 7.69125 14.1712 7.18312 14.4531L6.95898 14.5774C6.06374 15.0739 4.93625 14.6635 4.56961 13.7077L4.47782 13.4684C4.26972 12.9259 3.79668 12.529 3.22629 12.4183L2.97468 12.3694C1.96973 12.1743 1.3698 11.1352 1.70332 10.1674L1.78683 9.92503C1.97613 9.37568 1.8689 8.76756 1.50313 8.3161L1.34178 8.11694C0.697344 7.32153 0.905697 6.1399 1.78332 5.61286L2.00305 5.48091C2.50117 5.18177 2.80992 4.64699 2.81993 4.06603L2.82434 3.80976C2.84196 2.7862 3.7611 2.01495 4.77217 2.17534L5.02531 2.2155C5.59918 2.30653 6.17944 2.09533 6.56054 1.65672L6.72864 1.46324Z" fill="#255CE7"/>
                  <path d="M5.47656 7.57874L7.16077 9.26295L10.5292 5.89453" stroke="white" stroke-width="1.26316"/>
                </svg>
              </div>

              <div class="price">
                100 USDT
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'nft', params: { id: 'gold' }}" class="card -gold">
            <div class="image">
              <img :src="require('../images/statuses/Gold.png')" alt="Gold status">
              <div class="tag">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99996 0C2.89539 0 1.99996 0.89543 1.99996 2V6C1.99996 7.10457 2.89539 8 3.99996 8H7.99995C9.10452 8 9.99995 7.10457 9.99995 6V2C9.99995 0.89543 9.10452 0 7.99995 0H3.99996ZM0.99995 3.00001C0.99995 2.70654 1.06316 2.42784 1.1767 2.17676C0.482807 2.49059 0 3.18891 0 3.99999V7.99999C0 9.10456 0.89543 9.99999 2 9.99999H6C6.8111 9.99999 7.50943 9.51716 7.82325 8.82323C7.57216 8.93679 7.29344 9.00001 6.99995 9.00001H2.99995C1.89538 9.00001 0.99995 8.10458 0.99995 7.00001V3.00001Z" fill="white"/>
                </svg>
                700
              </div>
            </div>

            <div class="text">
              <div class="title">
                {{ $i18n.get(`Gold True Status card`) }}
              </div>

              <div class="market">
                TRUE Official
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72864 1.46324C7.40007 0.690472 8.59993 0.690473 9.27136 1.46324L9.43946 1.65672C9.82056 2.09533 10.4008 2.30653 10.9747 2.2155L11.2278 2.17534C12.2389 2.01495 13.158 2.7862 13.1757 3.80976L13.1801 4.06603C13.1901 4.64699 13.4988 5.18177 13.997 5.48091L14.2167 5.61286C15.0943 6.1399 15.3027 7.32153 14.6582 8.11695L14.4969 8.3161C14.1311 8.76756 14.0239 9.37569 14.2132 9.92503L14.2967 10.1674C14.6302 11.1352 14.0303 12.1743 13.0253 12.3694L12.7737 12.4183C12.2033 12.529 11.7303 12.9259 11.5222 13.4684L11.4304 13.7077C11.0638 14.6635 9.93626 15.0739 9.04102 14.5774L8.81688 14.4531C8.30875 14.1712 7.69125 14.1712 7.18312 14.4531L6.95898 14.5774C6.06374 15.0739 4.93625 14.6635 4.56961 13.7077L4.47782 13.4684C4.26972 12.9259 3.79668 12.529 3.22629 12.4183L2.97468 12.3694C1.96973 12.1743 1.3698 11.1352 1.70332 10.1674L1.78683 9.92503C1.97613 9.37568 1.8689 8.76756 1.50313 8.3161L1.34178 8.11694C0.697344 7.32153 0.905697 6.1399 1.78332 5.61286L2.00305 5.48091C2.50117 5.18177 2.80992 4.64699 2.81993 4.06603L2.82434 3.80976C2.84196 2.7862 3.7611 2.01495 4.77217 2.17534L5.02531 2.2155C5.59918 2.30653 6.17944 2.09533 6.56054 1.65672L6.72864 1.46324Z" fill="#255CE7"/>
                  <path d="M5.47656 7.57874L7.16077 9.26295L10.5292 5.89453" stroke="white" stroke-width="1.26316"/>
                </svg>
              </div>

              <div class="price">
                1 000 USDT
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'nft', params: { id: 'platinum' }}" class="card -platinum">
            <div class="image">
              <img :src="require('../images/statuses/Platinum.png')" alt="Platinum status">
              <div class="tag">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99996 0C2.89539 0 1.99996 0.89543 1.99996 2V6C1.99996 7.10457 2.89539 8 3.99996 8H7.99995C9.10452 8 9.99995 7.10457 9.99995 6V2C9.99995 0.89543 9.10452 0 7.99995 0H3.99996ZM0.99995 3.00001C0.99995 2.70654 1.06316 2.42784 1.1767 2.17676C0.482807 2.49059 0 3.18891 0 3.99999V7.99999C0 9.10456 0.89543 9.99999 2 9.99999H6C6.8111 9.99999 7.50943 9.51716 7.82325 8.82323C7.57216 8.93679 7.29344 9.00001 6.99995 9.00001H2.99995C1.89538 9.00001 0.99995 8.10458 0.99995 7.00001V3.00001Z" fill="white"/>
                </svg>
                70
              </div>
            </div>

            <div class="text">
              <div class="title">
                {{ $i18n.get(`Platinum True Status card`) }}
              </div>

              <div class="market">
                TRUE Official
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72864 1.46324C7.40007 0.690472 8.59993 0.690473 9.27136 1.46324L9.43946 1.65672C9.82056 2.09533 10.4008 2.30653 10.9747 2.2155L11.2278 2.17534C12.2389 2.01495 13.158 2.7862 13.1757 3.80976L13.1801 4.06603C13.1901 4.64699 13.4988 5.18177 13.997 5.48091L14.2167 5.61286C15.0943 6.1399 15.3027 7.32153 14.6582 8.11695L14.4969 8.3161C14.1311 8.76756 14.0239 9.37569 14.2132 9.92503L14.2967 10.1674C14.6302 11.1352 14.0303 12.1743 13.0253 12.3694L12.7737 12.4183C12.2033 12.529 11.7303 12.9259 11.5222 13.4684L11.4304 13.7077C11.0638 14.6635 9.93626 15.0739 9.04102 14.5774L8.81688 14.4531C8.30875 14.1712 7.69125 14.1712 7.18312 14.4531L6.95898 14.5774C6.06374 15.0739 4.93625 14.6635 4.56961 13.7077L4.47782 13.4684C4.26972 12.9259 3.79668 12.529 3.22629 12.4183L2.97468 12.3694C1.96973 12.1743 1.3698 11.1352 1.70332 10.1674L1.78683 9.92503C1.97613 9.37568 1.8689 8.76756 1.50313 8.3161L1.34178 8.11694C0.697344 7.32153 0.905697 6.1399 1.78332 5.61286L2.00305 5.48091C2.50117 5.18177 2.80992 4.64699 2.81993 4.06603L2.82434 3.80976C2.84196 2.7862 3.7611 2.01495 4.77217 2.17534L5.02531 2.2155C5.59918 2.30653 6.17944 2.09533 6.56054 1.65672L6.72864 1.46324Z" fill="#255CE7"/>
                  <path d="M5.47656 7.57874L7.16077 9.26295L10.5292 5.89453" stroke="white" stroke-width="1.26316"/>
                </svg>
              </div>

              <div class="price">
                10 000 USDT
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'nft', params: { id: 'black' }}" class="card -black">
            <div class="image">
              <img :src="require('../images/statuses/Black.png')" alt="Black status">
              <div class="tag">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99996 0C2.89539 0 1.99996 0.89543 1.99996 2V6C1.99996 7.10457 2.89539 8 3.99996 8H7.99995C9.10452 8 9.99995 7.10457 9.99995 6V2C9.99995 0.89543 9.10452 0 7.99995 0H3.99996ZM0.99995 3.00001C0.99995 2.70654 1.06316 2.42784 1.1767 2.17676C0.482807 2.49059 0 3.18891 0 3.99999V7.99999C0 9.10456 0.89543 9.99999 2 9.99999H6C6.8111 9.99999 7.50943 9.51716 7.82325 8.82323C7.57216 8.93679 7.29344 9.00001 6.99995 9.00001H2.99995C1.89538 9.00001 0.99995 8.10458 0.99995 7.00001V3.00001Z" fill="white"/>
                </svg>
                7
              </div>
            </div>

            <div class="text">
              <div class="title">
                {{ $i18n.get(`Black True Status card`) }}
              </div>

              <div class="market">
                TRUE Official
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72864 1.46324C7.40007 0.690472 8.59993 0.690473 9.27136 1.46324L9.43946 1.65672C9.82056 2.09533 10.4008 2.30653 10.9747 2.2155L11.2278 2.17534C12.2389 2.01495 13.158 2.7862 13.1757 3.80976L13.1801 4.06603C13.1901 4.64699 13.4988 5.18177 13.997 5.48091L14.2167 5.61286C15.0943 6.1399 15.3027 7.32153 14.6582 8.11695L14.4969 8.3161C14.1311 8.76756 14.0239 9.37569 14.2132 9.92503L14.2967 10.1674C14.6302 11.1352 14.0303 12.1743 13.0253 12.3694L12.7737 12.4183C12.2033 12.529 11.7303 12.9259 11.5222 13.4684L11.4304 13.7077C11.0638 14.6635 9.93626 15.0739 9.04102 14.5774L8.81688 14.4531C8.30875 14.1712 7.69125 14.1712 7.18312 14.4531L6.95898 14.5774C6.06374 15.0739 4.93625 14.6635 4.56961 13.7077L4.47782 13.4684C4.26972 12.9259 3.79668 12.529 3.22629 12.4183L2.97468 12.3694C1.96973 12.1743 1.3698 11.1352 1.70332 10.1674L1.78683 9.92503C1.97613 9.37568 1.8689 8.76756 1.50313 8.3161L1.34178 8.11694C0.697344 7.32153 0.905697 6.1399 1.78332 5.61286L2.00305 5.48091C2.50117 5.18177 2.80992 4.64699 2.81993 4.06603L2.82434 3.80976C2.84196 2.7862 3.7611 2.01495 4.77217 2.17534L5.02531 2.2155C5.59918 2.30653 6.17944 2.09533 6.56054 1.65672L6.72864 1.46324Z" fill="#255CE7"/>
                  <path d="M5.47656 7.57874L7.16077 9.26295L10.5292 5.89453" stroke="white" stroke-width="1.26316"/>
                </svg>
              </div>

              <div class="price">
                100 000 USDT
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'statuses-block',
}
</script>

<style lang="less">
.statuses-block {
  padding: 148px 0 158px 0;
  margin-bottom: 90px;
  background-image: url("../images/bg-statuses.png");
  background-size: 2560px;
  background-position: top center;
  color: #001A54;

  > .container {
    > .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      > div {
        font-weight: 800;
        font-size: 58px;
        line-height: 55px;
      }
      > .text {
        display: flex;
        width: 560px;
        line-height: 40px;
        gap: 22px;
        > span {
          font-weight: 800;
          font-size: 32px;
          > img {
            display: inline;
            width: 34px;
            height: 34px;
            margin-bottom: -3px;
          }
        }
        &:before {
          content: '';
          display: block;
          width: 68px;
          height: 2px;
          background: #001A54;
          margin-top: 21px;
        }
      }
    }
    > .cards {
      display: flex;
      justify-content: center;
      column-gap: 10px;

      > .card {
        color: #001A54;
        width: 228px;
        position: relative;
        top: 0;
        border-radius: 20px;
        background-size: contain;
        background: #FFFFFF;
        box-shadow: 0px 1px 2px 0px #001A5433;

        &:hover {
          top: -10px;
        }

        > .image {
          position: relative;
          > img {
            width: 100%;
            margin-bottom: 13px;
            border-radius: 20px;
          }
          > .tag {
            position: absolute;
            top: 15px;
            left: 15px;
            display: flex;
            padding: 4px 10px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: #FFF;
            background: #001A5466;
            border-radius: 100px;
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
          }
        }

        > .text {
          padding: 0 20px 17px 20px;
          > .title {
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 2px;
          }
          > .market {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #001A5499;
            gap: 4px;
            margin-bottom: 12px;
          }
          > .price {
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .statuses-block {
    padding: 90px 0 25px 0;
    margin-bottom: 90px;
    min-height: 670px;
    background-image: url("../images/bg-statuses.png");
    background-size: 2172px;
    background-position: top center;
    color: #001A54;
    overflow: hidden;
    width: 100%;

    > .container {
      position: relative;
      > .title {
        margin-bottom: 18px;
        > div {
          font-size: 44px;
          line-height: 55px;
        }
        > .text {
          margin-top: 12px;
          width: 470px;
          > span {
            font-size: 26px;
            line-height: 40px;
            > img {
              display: inline;
              width: 34px;
              height: 34px;
              margin-bottom: -3px;
            }
          }
          &:before {
            min-width: 58px;
          }
        }
      }
      > .cards {
        position: absolute;
        width: calc( 100% + 213px );
        padding-top: 10px;
        padding-right: 213px;
        padding-bottom: 8px;
        left: 0;
        display: flex;
        justify-content: flex-start;
        column-gap: 10px;
        overflow-x: scroll;

        > .card {
          min-width: 213px;

          &:hover {
            top: -10px;
          }

          > .image {
            > img {
              margin-bottom: 17px;
            }
            > .tag {
              top: 15px;
              left: 15px;
              padding: 4px 10px;
              gap: 6px;
              font-size: 10px;
              line-height: 16px;
            }
          }

          > .text {
            padding: 0 20px 18px 20px;
            > .title {
              font-size: 14px;
              line-height: 20px;
            }
            > .market {
              font-size: 12px;
              line-height: 18px;
              gap: 4px;
              margin-bottom: 12px;
            }
            > .price {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .statuses-block {
    padding: 70px 0 97px 0;
    margin-bottom: 60px;
    min-height: 600px;
    background-image: url("../images/bg-statuses.png");
    background-size: 1946px;
    background-position: top center;
    color: #001A54;
    overflow: hidden;
    width: 100%;

    > .container {
      position: relative;
      > .title {
        margin-bottom: 20px;
        > div {
          font-size: 36px;
          line-height: 55px;
        }
        > .text {
          margin-top: 14px;
          width: 382px;
          > span {
            font-size: 22px;
            line-height: 32px;
            > img {
              display: inline;
              width: 30px;
              height: 30px;
              margin-bottom: -7px;
            }
          }
          &:before {
            margin-top: 16px;
            min-width: 40px;
          }
        }
      }
      > .cards {
        position: absolute;
        width: calc( 100% + 213px );
        padding-top: 10px;
        padding-right: 213px;
        padding-bottom: 8px;
        left: 0;
        display: flex;
        justify-content: flex-start;
        column-gap: 10px;
        overflow-x: scroll;

        > .card {
          min-width: 213px;

          &:hover {
            top: -10px;
          }

          > .image {
            > img {
              margin-bottom: 17px;
            }
            > .tag {
              top: 15px;
              left: 15px;
              padding: 4px 10px;
              gap: 6px;
              font-size: 10px;
              line-height: 16px;
            }
          }

          > .text {
            padding: 0 20px 18px 20px;
            > .title {
              font-size: 14px;
              line-height: 20px;
            }
            > .market {
              font-size: 12px;
              line-height: 18px;
              gap: 4px;
              margin-bottom: 12px;
            }
            > .price {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .statuses-block {
    padding: 50px 20px 70px 20px;
    margin-bottom: 70px;
    min-height: 806px;
    background-image: url("../images/bg-statuses.png");
    background-size: 2616px;
    overflow: hidden;
    width: 100%;

    > .container {
      position: relative;
      > .title {
        margin-bottom: 20px;
        flex-direction: column;
        gap: 6px;
        > div {
          font-size: 32px;
          line-height: 48px;
        }
        > .text {
          margin-top: 0;
          width: 100%;
          gap: 14px;
          > span {
            font-size: 18px;
            line-height: 27px;
            padding-right: 20px;
            > img {
              width: 24px;
              height: 24px;
              margin-bottom: -7px;
            }
          }
          &:before {
            margin-top: 11px;
            min-width: 36px;
            width: 36px;
          }
        }
      }
      > .cards {
        position: relative;
        width: 100%;
        padding-top: 10px;
        padding-right: 0;
        padding-bottom: 0;
        overflow: visible;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 2px;

        > .card {
          width: 100%;
          display: flex;
          flex-direction: row;
          padding: 12px;
          gap: 12px;
          left: 0;

          &:hover {
            top: 0;
            left: -10px;
          }

          > .image {
            > img {
              display: block;
              width: 86px;
              margin-bottom: 0px;
              border-radius: 12px;
            }
            > .tag {
              display: none;
            }
          }

          > .text {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > .title {
              font-size: 16px;
              line-height: 18px;
            }
            > .market {
              font-size: 12px;
              line-height: 18px;
              gap: 4px;
              margin-bottom: 5px;
            }
            > .price {
              font-size: 16px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 429px) {
  .statuses-block {
    padding: 40px 15px 50px 15px;
    margin-bottom: 50px;
    min-height: 750px;
    height: 750px;
    background-size: 2432px 750px;
    overflow: hidden;
    width: 100%;

    > .container {
      position: relative;
      > .title {
        margin-bottom: 19px;
        flex-direction: column;
        gap: 5px;
        > div {
          font-size: 24px;
          line-height: 36px;
        }
        > .text {
          margin-top: 0;
          width: 100%;
          gap: 10px;
          > span {
            font-size: 14px;
            line-height: 21px;
            padding-right: 20px;
            > img {
              width: 24px;
              height: 24px;
              margin-bottom: -7px;
            }
          }
          &:before {
            margin-top: 11px;
            min-width: 30px;
            width: 30px;
          }
        }
      }
      > .cards {
        position: relative;
        width: 100%;
        padding-top: 0px;
        padding-right: 0;
        padding-bottom: 0;
        overflow: visible;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 2px;

        > .card {
          width: 100%;
          display: flex;
          flex-direction: row;
          padding: 12px;
          gap: 12px;
          left: 0;

          &:hover {
            top: 0;
            left: -10px;
          }

          > .image {
            > img {
              display: block;
              width: 86px;
              margin-bottom: 0px;
              border-radius: 12px;
            }
            > .tag {
              display: none;
            }
          }

          > .text {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > .title {
              font-size: 14px;
              line-height: 18px;
            }
            > .market {
              font-size: 11px;
              line-height: 16px;
              gap: 4px;
              margin-bottom: 6px;
            }
            > .price {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
</style>
