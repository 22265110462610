<template>
    <div class="with-us-block">
        <div class="container">
            <div class="head">
                <h2 class="title">
                    {{ $i18n.get(`Already with us`) }}
                </h2>

                <div class="buttons">
                    <button class="swipe-button" @click="prevPage($event)">
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.10109 14.6444L2.42257 8.22903L9.57812 1.35547" stroke="white" stroke-width="2"/>
                        </svg>
                    </button>

                    <button class="swipe-button" @click="nextPage($event)">
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.89891 14.6444L8.57743 8.22903L1.42188 1.35547" stroke="white" stroke-width="2"/>
                        </svg>
                    </button>
                </div>
            </div>

            <swiper ref="swiper" class="content" :options="swiperOptions" :slidesPerView="$store.state.isMobile ? 1 : 3">
                <swiper-slide class="item" v-for="(item, index) of items" :key="index">
                        <div class="card">
                            <div class="avatar"></div>

                            <div class="name">
                                {{ item.name }}
                            </div>

                            <div class="description">
                                {{ item.description }}
                            </div>

                            <div class="links">
                                <a href=''>
                                    <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.5" stroke="white" stroke-opacity="0.15"/>
                                        <g clip-path="url(#clip0_1_24999)">
                                            <path d="M23.0738 11.8214C22.5727 12.0412 22.0388 12.1869 21.4822 12.2576C22.0548 11.9157 22.4919 11.3784 22.6974 10.7309C22.1635 11.0492 21.574 11.274 20.9458 11.3995C20.4388 10.8597 19.7163 10.5254 18.9281 10.5254C17.3988 10.5254 16.1677 11.7667 16.1677 13.2883C16.1677 13.5073 16.1862 13.7178 16.2317 13.9182C13.9353 13.8062 11.9033 12.7056 10.5382 11.029C10.2999 11.4424 10.1601 11.9157 10.1601 12.4252C10.1601 13.3818 10.6527 14.2298 11.387 14.7208C10.9433 14.7123 10.5079 14.5835 10.139 14.3805C10.139 14.389 10.139 14.3999 10.139 14.4109C10.139 15.7532 11.0965 16.8681 12.3521 17.125C12.1273 17.1864 11.8822 17.2159 11.6279 17.2159C11.451 17.2159 11.2725 17.2058 11.1049 17.1688C11.4628 18.2627 12.4784 19.0669 13.686 19.093C12.7462 19.8281 11.5529 20.2711 10.2612 20.2711C10.0346 20.2711 9.81736 20.261 9.6001 20.2332C10.8237 21.0222 12.2738 21.4728 13.8376 21.4728C18.9205 21.4728 21.6995 17.2622 21.6995 13.6125C21.6995 13.4904 21.6953 13.3725 21.6894 13.2555C22.2376 12.8664 22.6982 12.3805 23.0738 11.8214Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_24999">
                                            <rect width="13.4737" height="13.4737" fill="white" transform="translate(9.6001 9.2627)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>

                                <a href=''>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="19.5" stroke="white" stroke-opacity="0.15"/>
                                        <path d="M19.7478 16.8321C18.1411 16.8321 16.8299 18.1433 16.8299 19.75C16.8299 21.3567 18.1411 22.6679 19.7478 22.6679C21.3545 22.6679 22.6657 21.3567 22.6657 19.75C22.6657 18.1433 21.3545 16.8321 19.7478 16.8321ZM28.4993 19.75C28.4993 18.5417 28.5103 17.3443 28.4424 16.1382C28.3746 14.7372 28.055 13.4939 27.0305 12.4695C26.0039 11.4428 24.7628 11.1254 23.3618 11.0576C22.1535 10.9897 20.9561 11.0007 19.75 11.0007C18.5417 11.0007 17.3443 10.9897 16.1382 11.0576C14.7372 11.1254 13.4939 11.445 12.4695 12.4695C11.4428 13.4961 11.1254 14.7372 11.0576 16.1382C10.9897 17.3465 11.0007 18.5439 11.0007 19.75C11.0007 20.9561 10.9897 22.1557 11.0576 23.3618C11.1254 24.7628 11.445 26.0061 12.4695 27.0305C13.4961 28.0572 14.7372 28.3746 16.1382 28.4424C17.3465 28.5103 18.5439 28.4993 19.75 28.4993C20.9583 28.4993 22.1557 28.5103 23.3618 28.4424C24.7628 28.3746 26.0061 28.055 27.0305 27.0305C28.0572 26.0039 28.3746 24.7628 28.4424 23.3618C28.5125 22.1557 28.4993 20.9583 28.4993 19.75ZM19.7478 24.2396C17.2633 24.2396 15.2582 22.2345 15.2582 19.75C15.2582 17.2655 17.2633 15.2604 19.7478 15.2604C22.2323 15.2604 24.2374 17.2655 24.2374 19.75C24.2374 22.2345 22.2323 24.2396 19.7478 24.2396ZM24.4213 16.1251C23.8412 16.1251 23.3728 15.6566 23.3728 15.0765C23.3728 14.4965 23.8412 14.028 24.4213 14.028C25.0014 14.028 25.4698 14.4965 25.4698 15.0765C25.47 15.2143 25.443 15.3507 25.3903 15.478C25.3377 15.6053 25.2605 15.7209 25.1631 15.8183C25.0657 15.9157 24.95 15.993 24.8227 16.0456C24.6954 16.0982 24.559 16.1252 24.4213 16.1251Z" fill="white"/>
                                    </svg>
                                </a>

                                <a href=''>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="19.5" stroke="white" stroke-opacity="0.15"/>
                                        <g clip-path="url(#clip0_1_11040)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1316 13.4713C29.0019 13.7004 29.6809 14.3711 29.9162 15.2277C30.5318 17.6713 30.4917 22.2913 29.9292 24.7731C29.6964 25.6297 29.0148 26.2978 28.1445 26.5295C25.6873 27.1277 14.6819 27.0538 12.367 26.5295C11.4966 26.3004 10.8177 25.6297 10.5823 24.7731C10.0016 22.444 10.0417 17.5186 10.5694 15.2404C10.8022 14.3838 11.4837 13.7157 12.354 13.484C15.6389 12.8095 26.9625 13.0271 28.1316 13.4713ZM18.3027 17.0234L23.5791 20.0016L18.3027 22.9798V17.0234Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_11040">
                                            <rect width="20.5625" height="14" fill="white" transform="translate(10 13)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>

                </swiper-slide>
            </swiper>

            <div class="swiper-pagination pagination" slot="pagination"></div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'with-us-block',
    components: {
        'swiper': Swiper,
        'swiper-slide': SwiperSlide
    },
    data() {
        return {
            items: [
                {
                    avatar: '../images/avatars/MurakamiSuzuko.jpg',
                    name: 'Murakami Suzuko',
                    description: 'MFTMKKUS',
                    tw_link: '',
                    inst_link: '',
                    yt_link: ''
                },
                {
                    avatar: '../images/avatars/KevinNelson.webp',
                    name: 'Kevin Nelson',
                    description: 'kelvinnkq',
                    tw_link: '',
                    inst_link: '',
                    yt_link: ''
                },
                {
                    avatar: '../images/avatars/MikeDidko',
                    name: 'Mike Didko',
                    description: 'Doodles',
                    tw_link: '',
                    inst_link: '',
                    yt_link: ''
                },
                {
                    avatar: '../images/avatars/JosieBellini.jpg',
                    name: 'Josie Bellini',
                    description: 'CyberBrokers',
                    tw_link: '',
                    inst_link: '',
                    yt_link: ''
                }
            ]
        }
    },
    computed: {
        swiperOptions() {
            return {
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets'
                }
            }
        },
        slidesPerView() {
            if (this.$store.state.isMobile) {
                return 2
            } else if (this.$store.state.isFullDesktop) {
                return 4
            } else {
                return 3
            }
        },
        spaceBetween() {
            if (this.$store.state.isMobile) {
                return 10
            } else if (this.$store.state.isFullDesktop) {
                return 20
            } else {
                return 18
            }
        }
    },
    methods: {
        nextPage(event) {
            this.$refs.swiper.$swiper.navigation.onNextClick(event)
        },
        prevPage(event) {
            this.$refs.swiper.$swiper.navigation.onPrevClick(event)
        },
    }
}
</script>

<style lang="less">
.with-us-block {
    background-color: #0D255D;

    > .container {
        width: var(--main-width-full);
        padding: 60px 0 90px;
        margin: auto;

        > .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;

            > .title {
                font-weight: 700;
                font-size: 46px;
                line-height: 60px;
            }

            > .buttons {
                display: flex;
                column-gap: 5px;
                align-items: center;

                > .swipe-button {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    border: 1px solid #364978;
                    background-color: #253A6D;
                    display: flex;
                    align-items: center;
                    padding: 14px 17px;
                    transition: all 0.2s;
                    cursor: pointer;

                    svg {
                        path {
                            stroke: var(--white);
                            transition: all 0.2s;
                        }
                    }

                    &:hover {
                        background-color: #3D517D;
                    }
                }
            }
        }

        > .swiper-container {
            > .swiper-wrapper {
                > .item {
                    > .card {
                        padding: 40px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                        background-color: #193065;
                        border-radius: 30px;

                        > .avatar {
                            background-image: url(../images/avatars/KevinNelson.webp);
                            background-size: cover;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                        }

                        > .name {
                            margin-top: 16px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--white);
                        }

                        > .description {
                            margin-top: 3px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: rgba(255, 255, 255, 0.65);
                        }

                        > .links {
                            display: flex;
                            column-gap: 8px;
                            margin-top: 33px;

                            > a {
                                position: relative;

                                &:first-child::after {
                                    content: '';
                                    position: absolute;
                                    width: 45px;
                                    height: 1px;
                                    background-color: #3B4F7C;
                                    left: -55px;
                                    top: 20px
                                }

                                &:last-child::before {
                                    content: '';
                                    position: absolute;
                                    width: 45px;
                                    height: 1px;
                                    background-color: #3B4F7C;
                                    right: -55px;
                                    top: 20px
                                }
                            }
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            display: none;
            z-index: 0 !important;
        }
    }
}

@media (max-width: 1279px){
    .with-us-block {
        > .container {
            > .head {
                > .title {
                    font-size: 36px;
                }
            }

            > .swiper-container {
                > .swiper-wrapper {
                    > .item {
                        > .card {
                            > .links {
                                > a {
                                    &:first-child::after {
                                        width: 40px;
                                        left: -50px;
                                    }

                                    &:last-child::before {
                                        width: 40px;
                                        right: -50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px){
    .with-us-block {
        > .container {
            padding: 54px 0 74px;
            > .head {
                > .title {
                    font-size: 28px;
                    line-height: 60px;
                }
            }

            > .swiper-container {
                > .swiper-wrapper {
                    > .item {
                        > .card {
                            > .links {
                                > a {
                                    &:first-child::after {
                                        content: none;
                                    }

                                    &:last-child::before {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px){
    .with-us-block {
        > .container {
            padding: 32px 15px 65px;

            > .head {
                margin-bottom: 21px;
                text-align: center;
                align-items: center;
                justify-content: center;

                > .title {
                    font-size: 22px;
                    line-height: 31px;
                }

                > .buttons {
                    display: none;
                }
            }

            > .swiper-container {
                > .swiper-wrapper {
                    > .item {
                        > .card {
                            padding: 20px 0;

                            > .avatar {
                                width: 70px;
                                height: 70px;
                            }

                            > .name {
                                font-size: 12px;
                                line-height: 18px;
                                margin-top: 12px;
                            }

                            > .description {
                                margin-top: 0;
                                font-size: 12px;
                                line-height: 20px;
                            }

                            > .links {
                                margin-top: 18px;

                                > a {
                                    > svg {
                                        width: 32px;
                                        height: 32px;
                                    }

                                    &:first-child::after {
                                        content: none;
                                    }

                                    &:last-child::before {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > .swiper-pagination {
                display: block;
                width: 100%;
                display: flex;
                justify-content: center;
                -moz-column-gap: 6px;
                column-gap: 6px;
                margin: 15px -15px 0;


                > .swiper-pagination-bullet {
                    width: 6px !important;
                    height: 6px;
                }

                > .swiper-pagination-bullet-active {
                    background: #EEB012
                }
            }

            > .pagination {
                display: flex;
                justify-content: center;
                column-gap: 6px;
                width: 100%;
                margin: 20px -15px 0;

                > .swiper-pagination-bullet {
                    width: 6px;
                    height: 6px;
                    background-color: #4A5C86;
                    transition: .3s;
                    border-radius: 50%;

                    &-active {
                        width: 6px;
                        height: 6px;
                        background-color: var(--orange);
                        transition: .3s;
                    }
                }
            }
        }
    }
}
</style>