<template>
    <div class="benefits-status-block">
        <div class="container">
            <div class="title">
              {{ $i18n.get(`All benefits by status`) }}
              <img :src="require('../images/crown-image.png')">
            </div>
            
            <market-status-table theme="dark"></market-status-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'benefits-status-block'
}
</script>

<style lang="less">
.benefits-status-block {
  background: #031337;
    > .container {
        width: var(--main-width-full);
        margin: auto;
        text-align: center;
        align-items: center;
        border-radius: 100px;
        background: #FFFFFF0F;
        padding: 85px 62px 79px 62px;

        > .title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;
          font-weight: 700;
          font-size: 46px;
          line-height: 66px;
          > img {
            width: 58px;
          }
        }

        .status-table-block > .container {
          width: 100%;
          background: transparent;
          > .content {
            padding: 0;
            .tabs {
              border-color: #FFFFFF26;
              padding-bottom: 25px;
              > .tab {
                padding: 6px 22px;
                color: #FFFFFF73;
                &.-active {
                  color: #FFF;
                }
              }
              > .arrow {
                background: #152041;
                border-color: #FFFFFF26;
              }
            }
            > .table {
              > .cell {
                background: #FFFFFF1A;
                &.-no-active {
                  background: #FFFFFF08;
                }
              }
            }
          }
        }
   }
}

@media (max-width: 1439px) {
  .benefits-status-block {
    > .container {
      padding: 46px 40px 59px 40px;
      .title {
        font-size: 36px;
        line-height: 51px;
        > img {
          width: 48px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
    .benefits-status-block {
        > .container {
          width: 100% !important;
          margin: 0;
          border-radius: 0;
          background: #FFFFFF0F;
          padding: 70px 50px 87px 50px;
          .title {
            font-size: 32px;
            line-height: 46px;
            > img {
              width: 45px;
            }
          }
        }
    }
}

@media (max-width: 767px) {
  .benefits-status-block {
    > .container {
      width: 100% !important;
      margin: 0;
      border-radius: 0;
      background: #FFFFFF0F;
      padding: 60px 20px 61px 20px !important;
      .title {
        font-size: 26px;
        line-height: 37px;
        > img {
          width: 46px;
        }
      }
      .status-table-block > .container {
        > .content {
          .tabs {
            padding-bottom: 8px;
            justify-content: space-between;
            > .tab {
              padding: 6px 13px;
              &.-active {
                padding: 6px 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 429px) {
  .benefits-status-block {
    > .container {
      width: 100% !important;
      margin: 0;
      border-radius: 0;
      background: #FFFFFF0F;
      padding: 50px 15px !important;
      .title {
        font-size: 20px;
        line-height: 29px;
        > img {
          width: 34px;
        }
      }
      .status-table-block > .container {
        > .content {
          .tabs {
            padding-bottom: 3px;
            justify-content: space-between;
            > .tab {
              padding: 5px 0px;
              font-size: 12px;
              &.-active {
                padding: 5px 10px;
              }
            }
          }
          .table {
            > .cell {
              > .info {
                font-size: 10px;
                line-height: 11px;
                > .circle {
                  > div {
                    width: 24px;
                    height: 24px;
                  }
                  > canvas {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
