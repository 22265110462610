<template>
    <div class="stacking-block">
        <div class="container">
            <div class="text">
                <div class="title">
                    {{ $i18n.get(`Staking TFT`) }}
                </div>

                <div v-if="$store.state.isMobile" class="image">
                    <img :src="require('../images/rainbow-horse.webp')" alt="">
                </div>

                <div class="description">
                    {{ $i18n.get(`Stake TFT and receive a reward every day. Available staking period depends on the Status card (30-360 days). The rate is adjustable and depends on the staking period, Status card, the amount, and the total amount of the staked funds.`) }}
                </div>
            </div>

            <div v-if="!$store.state.isMobile" class="image">
                <img :src="require('../images/rainbow-horse.webp')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stacking-block'
}
</script>

<style lang="less">
.stacking-block {
    > .container {
        width: var(--main-width-full);
        margin: auto;
        display: flex;
        padding: 0 50px 50px;

        > .text {
            max-width: 427px;
            margin-right: 100px;
            margin-top: 155px;

            > .title {
                font-size: 36px;
                line-height: 42px;
                font-weight: 700;
                margin-bottom: 18px;
            }

            > .description {
                font-size: 18px;
                line-height: 32px;
                font-weight: 500;
            }
        }

        > .image {
            width: 511px;
            position: relative;
            object-fit: cover;
            z-index: 1;

            > img {
                width: 100%;
            }

            &::before {
                content: '';
                background: radial-gradient(50% 50% at 50% 50%, #3F4DCE 0%, rgba(55, 89, 173, 0) 100%, rgba(63, 77, 206, 0) 100%);
                opacity: 0.5;
                filter: blur(170px);
                width: 90%;
                height: 70%;
                position: absolute;
                top: 0;
                z-index: -1;
            }
        }
    }
}

@media (max-width: 1279px) {
    .stacking-block {
        > .container {
            padding: 0px 0 80px;

            > .text {
                max-width: 336px;
                margin-top: 100px;

                > .title {
                    font-size: 36px;
                    line-height: 42px;
                }

                > .description {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            > .image {
                width: 398px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .stacking-block {
        > .container {
            padding: 0px 0 25px;

            > .text {
                max-width: 295px;
                margin-top: 50px;
                margin-right: 65px;

                > .title {
                    font-size: 28px;
                    line-height: 42px;
                    margin-bottom: 10px;
                }

                > .description {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            > .image {
                width: 298px;
            }
        }
    }
}

@media (max-width: 767px) {
    .stacking-block {
        > .container {
            padding: 0px 30px 0px;
            align-items: center;
            justify-content: center;
            text-align: left;
            margin-bottom: 45px;

            > .text {
                max-width: none;
                margin: 0;

                > .title {
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 6px;
                }

                > .image {
                    width: 100%;
                    object-fit: cover;
                    position: relative;
                    z-index: 1;

                    > img {
                        width: 100%;
                    }

                    &::before {
                        content: '';
                        background: radial-gradient(50% 50% at 50% 50%, #3F4DCE 0%, rgba(55, 89, 173, 0) 100%, rgba(63, 77, 206, 0) 100%);
                        opacity: 0.5;
                        filter: blur(72.9962px);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        z-index: -1;
                    }
                }

                > .description {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>