<template>
    <div class="mission-block" id="mission">
         <div class="container">
             <div class="information">
                 <h2 class="title">
                     {{ $i18n.get(`TRUE Mission`) }}
                 </h2>

                 <div class="text">
                     <h3 class="subtitle">
                         {{ $i18n.get(`To unleash the potential of NFT technology and expand its functionality for a widespread use.`) }}
                     </h3>

                     <div class="paragraph">
                         {{ $i18n.get(`TRUE Ecosystem is a multi-chain infrastructure that guides brands, game developers and users to the world of Web 3.0 and allows them to easily interact with GameFi and next generation NFT tokens, applying their benefits in everyday life.`) }}
                     </div>
                 </div>
            </div>

            <div class="image">
                <img :src="require(`../images/tanozebr.webp`)" alt="">
            </div>
         </div>
    </div>
</template>

<script>
export default {
    name: 'MissionBlock',
}
</script>

<style lang="less">
.mission-block {
    padding: 190px 0 0;
    margin-bottom: 190px;
    overflow: hidden;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .information {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: -45px;

            > .title {
                font-weight: 700;
                font-size: 46px;
                line-height: 66px;
            }

            > .text {
                position: relative;
                max-width: 730px;
                padding-top: 13px;
                text-align: left;

                &:before {
                    content: '';
                    position: absolute;
                    top: 33px;
                    left: -70px;
                    display: block;
                    width: 50px;
                    height: 3px;
                    background: var(--white);
                    border-radius: 100px;
                }

                > .subtitle {
                    margin-bottom: 24px;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 40px;
                }

                > .paragraph {
                    max-width: 687px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

        > .image {
            height: 660px;
            object-fit: cover;

            > img {
                width: 100%;
                border-radius: 50px;
            }
        }
    }
}

@media (max-width: 1439px) {
    .mission-block {
        padding: 140px 0 0;
        margin-bottom: 140px;
    }
}

@media (max-width: 1279px) {
    .mission-block {
        padding: 120px 0 0;
        margin-bottom: 120px;

        > .container {
            > .information {
                margin-bottom: -38px;

                > .title {
                    font-size: 36px;
                    line-height: 52px;
                }

                > .text {
                    max-width: 460px;

                    > .subtitle {
                        margin-bottom: 10px;
                        font-size: 24px;
                        line-height: 36px;
                    }

                    > .paragraph {
                        max-width: 100%;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }

            > .image {
                height: 466px;
                object-fit: cover;

                > img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .mission-block {
        padding: 100px 0 0;
        margin-bottom: 100px;

        > .container {
            > .information {
                margin-bottom: -35px;

                > .title {
                    font-size: 28px;
                    line-height: 40px;
                }

                > .text {
                    max-width: 381px;
                    padding-top: 9px;

                    &:before {
                        top: 20px;
                        left: -53px;
                        width: 40px;
                        height: 2px;
                    }

                    > .subtitle {
                        margin-bottom: 9px;
                        font-size: 18px;
                        line-height: 25px;
                    }

                    > .paragraph {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }

            > .image {
                height: 368px;
            }
        }
    }
}

@media (max-width: 767px) {
    .mission-block {
        position: relative;
        padding: 50px 0 0;
        margin-bottom: 50px;

        > .container {
            position: relative;

            > .information {
                position: relative;
                flex-direction: column;
                margin-bottom: 0;
                padding: 0 30px;
                z-index: 2;

                > .title {
                    margin-bottom: 11px;
                    font-size: 22px;
                    line-height: 32px;
                }

                > .text {
                    max-width: 100%;

                    &:before {
                        display: none;
                    }

                    > .subtitle {
                        margin-bottom: 207px;
                        font-size: 14px;
                        line-height: 21px;
                    }

                    > .paragraph {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }

            > .image {
                position: absolute;
                top: 75px;
                left: 0;
                width: 506px;
                height: 279px;
                left: calc(50% - 251px);

                > img {
                    z-index: -1;
                    border-radius: 20px;
                }
            }
        }
    }
}
</style>