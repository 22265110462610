<template>
    <div class="community-block">
        <div class="container">
           <div class="title" v-html="$i18n.get(`Join our community`)"></div>

           <div class="description" v-html="$i18n.get(`Don't miss the upcoming NFT drops.<br>And also take part in contests to win a status card or WL spots.`)"></div>

           <div class="links">
                <a :href="$store.state.links.telegramNews" class="tg" target="_blank">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.353259 8.6426L4.96166 10.3626L6.74539 16.0991C6.85953 16.4665 7.30879 16.6023 7.60699 16.3585L10.1758 14.2643C10.4451 14.0449 10.8286 14.034 11.11 14.2383L15.7432 17.6021C16.0622 17.8339 16.5141 17.6591 16.5941 17.2737L19.9882 0.947599C20.0755 0.526533 19.6618 0.175266 19.2609 0.330333L0.347859 7.6264C-0.118874 7.8064 -0.114808 8.4672 0.353259 8.6426ZM6.45793 9.447L15.4645 3.89987C15.6263 3.80047 15.7929 4.01933 15.6539 4.14827L8.22086 11.0576C7.95959 11.3008 7.79106 11.6263 7.74333 11.9795L7.49013 13.8559C7.45659 14.1065 7.10466 14.1313 7.03553 13.8889L6.06173 10.4672C5.95019 10.0769 6.11273 9.66007 6.45793 9.447Z" fill="white"/>
                    </svg>

                    {{ $i18n.get(`TG Channel`) }}
                </a>
                <a href="https://t.me/true_community" class="tg" target="_blank">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.353259 8.6426L4.96166 10.3626L6.74539 16.0991C6.85953 16.4665 7.30879 16.6023 7.60699 16.3585L10.1758 14.2643C10.4451 14.0449 10.8286 14.034 11.11 14.2383L15.7432 17.6021C16.0622 17.8339 16.5141 17.6591 16.5941 17.2737L19.9882 0.947599C20.0755 0.526533 19.6618 0.175266 19.2609 0.330333L0.347859 7.6264C-0.118874 7.8064 -0.114808 8.4672 0.353259 8.6426ZM6.45793 9.447L15.4645 3.89987C15.6263 3.80047 15.7929 4.01933 15.6539 4.14827L8.22086 11.0576C7.95959 11.3008 7.79106 11.6263 7.74333 11.9795L7.49013 13.8559C7.45659 14.1065 7.10466 14.1313 7.03553 13.8889L6.06173 10.4672C5.95019 10.0769 6.11273 9.66007 6.45793 9.447Z" fill="white"/>
                    </svg>

                    {{ $i18n.get(`TG Chat`) }}
                </a>
                <a :href="$store.state.links.twitter" class="tw" target="_blank">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_169_16517)">
                            <path d="M20 3.79875C19.2563 4.125 18.4637 4.34125 17.6375 4.44625C18.4875 3.93875 19.1363 3.14125 19.4412 2.18C18.6488 2.6525 17.7738 2.98625 16.8412 3.1725C16.0887 2.37125 15.0162 1.875 13.8462 1.875C11.5763 1.875 9.74875 3.7175 9.74875 5.97625C9.74875 6.30125 9.77625 6.61375 9.84375 6.91125C6.435 6.745 3.41875 5.11125 1.3925 2.6225C1.03875 3.23625 0.83125 3.93875 0.83125 4.695C0.83125 6.115 1.5625 7.37375 2.6525 8.1025C1.99375 8.09 1.3475 7.89875 0.8 7.5975C0.8 7.61 0.8 7.62625 0.8 7.6425C0.8 9.635 2.22125 11.29 4.085 11.6712C3.75125 11.7625 3.3875 11.8062 3.01 11.8062C2.7475 11.8062 2.4825 11.7913 2.23375 11.7362C2.765 13.36 4.2725 14.5538 6.065 14.5925C4.67 15.6838 2.89875 16.3412 0.98125 16.3412C0.645 16.3412 0.3225 16.3263 0 16.285C1.81625 17.4563 3.96875 18.125 6.29 18.125C13.835 18.125 17.96 11.875 17.96 6.4575C17.96 6.27625 17.9538 6.10125 17.945 5.9275C18.7588 5.35 19.4425 4.62875 20 3.79875Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_169_16517" target="_blank">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                    {{ $i18n.get(`Twitter`) }}
                </a>
                <a :href="$store.state.links.instagram" v-if="$store.state.links.instagram" class="in" target="_blank">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4973 6.66525C8.66101 6.66525 7.1625 8.16376 7.1625 10C7.1625 11.8362 8.66101 13.3348 10.4973 13.3348C12.3335 13.3348 13.832 11.8362 13.832 10C13.832 8.16376 12.3335 6.66525 10.4973 6.66525ZM20.499 10C20.499 8.61907 20.5115 7.25064 20.434 5.87221C20.3564 4.27113 19.9912 2.85017 18.8204 1.67938C17.6471 0.506085 16.2286 0.14334 14.6275 0.065788C13.2466 -0.0117644 11.8782 0.000744113 10.4998 0.000744113C9.11882 0.000744113 7.7504 -0.0117644 6.37197 0.065788C4.77089 0.14334 3.34993 0.508587 2.17913 1.67938C1.00584 2.85267 0.643096 4.27113 0.565544 5.87221C0.487991 7.25314 0.5005 8.62157 0.5005 10C0.5005 11.3784 0.487991 12.7494 0.565544 14.1278C0.643096 15.7289 1.00834 17.1498 2.17913 18.3206C3.35243 19.4939 4.77089 19.8567 6.37197 19.9342C7.7529 20.0118 9.12132 19.9993 10.4998 19.9993C11.8807 19.9993 13.2491 20.0118 14.6275 19.9342C16.2286 19.8567 17.6496 19.4914 18.8204 18.3206C19.9937 17.1473 20.3564 15.7289 20.434 14.1278C20.514 12.7494 20.499 11.3809 20.499 10ZM10.4973 15.131C7.65784 15.131 5.36629 12.8394 5.36629 10C5.36629 7.16058 7.65784 4.86903 10.4973 4.86903C13.3367 4.86903 15.6282 7.16058 15.6282 10C15.6282 12.8394 13.3367 15.131 10.4973 15.131ZM15.8384 5.8572C15.1754 5.8572 14.6401 5.32184 14.6401 4.65889C14.6401 3.99594 15.1754 3.46058 15.8384 3.46058C16.5013 3.46058 17.0367 3.99594 17.0367 4.65889C17.0369 4.81631 17.006 4.97222 16.9459 5.1177C16.8857 5.26317 16.7974 5.39535 16.6861 5.50666C16.5748 5.61798 16.4426 5.70624 16.2972 5.76639C16.1517 5.82654 15.9958 5.8574 15.8384 5.8572Z" fill="white"/>
                    </svg>

                    {{ $i18n.get(`Instagram`) }}
                </a>
               <a :href="$store.state.links.discord" v-if="$store.state.links.discord" target="_blank" class="dc">
                 <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M15.8161 1.17085C14.6509 0.6257 13.405 0.229504 12.1025 0.00390625C11.9426 0.293114 11.7557 0.682105 11.6268 0.991546C10.2422 0.78332 8.87037 0.78332 7.51123 0.991546C7.3824 0.682105 7.19128 0.293114 7.02988 0.00390625C5.72596 0.229504 4.47868 0.627156 3.3135 1.17374C0.963341 4.72514 0.326251 8.18834 0.644797 11.6024C2.20355 12.7664 3.71417 13.4735 5.19929 13.9362C5.56598 13.4316 5.89301 12.8951 6.17474 12.3297C5.63817 12.1258 5.12426 11.8742 4.63866 11.5821C4.76749 11.4867 4.8935 11.3869 5.01524 11.2842C7.97701 12.6695 11.195 12.6695 14.1214 11.2842C14.2446 11.3869 14.3706 11.4867 14.498 11.5821C14.011 11.8756 13.4956 12.1273 12.9591 12.3312C13.2408 12.8951 13.5664 13.433 13.9345 13.9377C15.4211 13.4749 16.9331 12.7678 18.4919 11.6024C18.8656 7.64464 17.8534 4.21325 15.8161 1.17085ZM6.57824 9.50276C5.68915 9.50276 4.96002 8.67274 4.96002 7.66198C4.96002 6.65122 5.67358 5.81977 6.57824 5.81977C7.48293 5.81977 8.21203 6.64977 8.19646 7.66198C8.19787 8.67274 7.48293 9.50276 6.57824 9.50276ZM12.5584 9.50276C11.6693 9.50276 10.9402 8.67274 10.9402 7.66198C10.9402 6.65122 11.6537 5.81977 12.5584 5.81977C13.4631 5.81977 14.1922 6.64977 14.1766 7.66198C14.1766 8.67274 13.4631 9.50276 12.5584 9.50276Z" fill="white"/>
                 </svg>

                 {{ $i18n.get(`Discord`) }}
               </a>
           </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'community-block',
}
</script>

<style lang="less">
.community-block {
  background-image: url(../images/bg-community.png);
  background-size: auto 466px;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 1954px;
  margin: auto;
  color: var(--white);

  > .container {
    width: var(--main-width-full);
    text-align: center;
    align-items: center;
    margin: auto;
    padding: 126px 0 110px;

    > .title {
      font-size: 46px;
      font-weight: 700;
      line-height: 66px;
    }

    > .description {
      font-size: 20px;
      font-weight: 600;
      line-height: 34px;
      margin-top: 12px;
    }

    > .links {
      margin-top: 34px;
      display: flex;
      column-gap: 10px;
      justify-content: center;

      > a {
        padding: 12px 18px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        display: flex;
        align-items: center;
        column-gap: 8px;
        border-radius: 100px;
        color: var(--white);
        justify-content: center;
        width: 150px;
        height: 50px;

        &.tg {
          background: linear-gradient(102.48deg, #1079D9 8.34%, #3B89FE 111.45%);
          &:hover {
            background: linear-gradient(102.48deg, #3194f0 8.34%, #62a1fe 111.45%);
          }
        }

        &.tw {
          background: linear-gradient(102.48deg, #108ED9 8.34%, #55BDFF 111.45%);
          &:hover {
            background: linear-gradient(102.48deg, #31a8f0 8.34%, #77caff 111.45%);
          }
        }

        &.dc {
          background: linear-gradient(102.48deg, #4450E4 8.34%, #313FEC 111.45%);
          &:hover {
            background: linear-gradient(102.48deg, #6973e9 8.34%, #5a65f0 111.45%);
          }
        }

        &.in {
          background: linear-gradient(146.41deg, #4367CA -9.09%, #B431BA 33.17%, #F24D56 70.67%, #FFCB52 129.44%);
          &:hover {
            background: linear-gradient(146.41deg, #6985d5 -9.09%, #cb51d1 33.17%, #f57178 70.67%, #ffd575 129.44%);
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .community-block {
    background-size: auto 430px;
    > .container {
      padding: 100px 0 118px 0;

      > .title {
        font-size: 40px;
        line-height: 57px;
        margin-bottom: 10px;
      }

      > .description {
        font-size: 18px;
        line-height: 32px;
        margin-top: 0;
        margin-bottom: 30px;
      }

      > .links {
        margin-top: 0;
        > a {
          padding: 12px 18px;
          font-size: 13px;
          line-height: 19px;
          width: 150px;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .community-block {
    background-size: auto 360px;
    > .container {
      padding: 85px 0 89px 0;

      > .title {
        font-size: 34px;
        line-height: 48px;
        margin-bottom: 10px;
      }

      > .description {
        font-size: 16px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 30px;
      }

      > .links {
        margin-top: 0;
        gap: 5px;
        > a {
          gap: 6px;
          padding: 8px 20px;
          font-size: 14px;
          line-height: 19px;
          width: auto;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .community-block {
    background-size: auto 430px;
    > .container {
      padding: 60px 0 75px 0 !important;

      > .title {
        font-size: 30px;
        line-height: 43px;
        margin-bottom: 10px;
      }

      > .description {
        font-size: 14px;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 24px;
        padding: 0 40px;
      }

      > .links {
        margin-top: 0;
        gap: 10px;
        flex-wrap: wrap;
        > a {
          gap: 6px;
          padding: 8px 20px;
          font-size: 14px;
          line-height: 19px;
          width: 150px;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 429px) {
  .community-block {
    background-size: auto 360px;
    > .container {
      padding: 50px 0 54px 0 !important;

      > .title {
        font-size: 22px;
        line-height: 31px;
        margin-bottom: 10px;
      }

      > .description {
        font-size: 12px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 18px;
        padding: 0 20px;
      }

      > .links {
        margin-top: 0;
        gap: 8px;
        flex-wrap: wrap;
        > a {
          gap: 6px;
          padding: 8px 14px;
          font-size: 12px;
          line-height: 21px;
          width: 126px;
          height: 38px;
        }
      }
    }
  }
}
</style>
