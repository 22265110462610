import { render, staticRenderFns } from "./StatusCardsBlock.vue?vue&type=template&id=07f32365&"
import script from "./StatusCardsBlock.vue?vue&type=script&lang=js&"
export * from "./StatusCardsBlock.vue?vue&type=script&lang=js&"
import style0 from "./StatusCardsBlock.vue?vue&type=style&index=0&id=07f32365&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports