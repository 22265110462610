<template>
    <div class="nft-craft-block">
        <div class="container">
            <div class="text">
                <div class="title">
                    {{ $i18n.get(`NFT craft`) }}
                </div>

                <div v-if="$store.state.isMobile" class="images">
                    <div class="silver-horse">
                        <img :src="require('../images/silver-horse.webp')" alt="">
                    </div>

                    <div class="arrow">
                        <svg width="124" height="35" viewBox="0 0 124 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M101.677 -9.72103e-07L123.916 18.2842L101.677 35L101.677 25.9637L57.9994 25.9637L57.9994 9.50774L101.677 9.50774L101.677 -9.72103e-07Z" fill="url(#paint0_linear_1_20484)"/>
                        <path d="M51 10.5L51 24.5" stroke="#77DA29" stroke-opacity="0.5" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M42.832 10.5L42.832 24.5" stroke="#77DA29" stroke-opacity="0.4" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M34.668 10.5L34.668 24.5" stroke="#77DA29" stroke-opacity="0.35" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M26.5 10.5L26.5 24.5" stroke="#77DA29" stroke-opacity="0.26" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M18.332 10.5001L18.332 24.5001" stroke="#77DA29" stroke-opacity="0.2" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M10.168 10.5L10.168 24.5" stroke="#77DA29" stroke-opacity="0.15" stroke-width="2.33333" stroke-linecap="square"/>
                        <path d="M2 10.5001L2 24.5001" stroke="#77DA29" stroke-opacity="0.1" stroke-width="2.33333" stroke-linecap="square"/>
                        <defs>
                        <linearGradient id="paint0_linear_1_20484" x1="123.916" y1="17.5" x2="26.3086" y2="17.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#77DA29"/>
                        <stop offset="1" stop-color="#77DA29" stop-opacity="0"/>
                        </linearGradient>
                        </defs>
                        </svg>
                    </div>

                    <div class="bronze-horse">
                        <img :src="require('../images/bronze-horse.webp')" alt="">
                    </div>
                </div>

                <div class="description" v-html="$i18n.get(`You can craft a major status NFT from several minor status NFTs at a ratio of 1:9.<br>A maximum number of tier-1 NFTs is limited.`)"></div>
            </div>

            <div v-if="!$store.state.isMobile" class="images">
                <div class="silver-horse">
                    <img :src="require('../images/silver-horse.webp')" alt="">
                </div>

                <div class="arrow">
                    <svg width="124" height="35" viewBox="0 0 124 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M101.677 -9.72103e-07L123.916 18.2842L101.677 35L101.677 25.9637L57.9994 25.9637L57.9994 9.50774L101.677 9.50774L101.677 -9.72103e-07Z" fill="url(#paint0_linear_1_20484)"/>
                    <path d="M51 10.5L51 24.5" stroke="#77DA29" stroke-opacity="0.5" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M42.832 10.5L42.832 24.5" stroke="#77DA29" stroke-opacity="0.4" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M34.668 10.5L34.668 24.5" stroke="#77DA29" stroke-opacity="0.35" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M26.5 10.5L26.5 24.5" stroke="#77DA29" stroke-opacity="0.26" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M18.332 10.5001L18.332 24.5001" stroke="#77DA29" stroke-opacity="0.2" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M10.168 10.5L10.168 24.5" stroke="#77DA29" stroke-opacity="0.15" stroke-width="2.33333" stroke-linecap="square"/>
                    <path d="M2 10.5001L2 24.5001" stroke="#77DA29" stroke-opacity="0.1" stroke-width="2.33333" stroke-linecap="square"/>
                    <defs>
                    <linearGradient id="paint0_linear_1_20484" x1="123.916" y1="17.5" x2="26.3086" y2="17.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#77DA29"/>
                    <stop offset="1" stop-color="#77DA29" stop-opacity="0"/>
                    </linearGradient>
                    </defs>
                    </svg>
                </div>

                <div class="bronze-horse">
                    <img :src="require('../images/bronze-horse.webp')" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'nft-craft-block'
}
</script>

<style lang="less">
.nft-craft-block {
    > .container {
        padding: 90px 0 117px ;
        width: var(--main-width-full);
        margin: auto;
        background: rgba(13, 37, 93, 1);
        border-radius: 50px;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 59px;

        > .text {
            > .title {
                font-size: 36px;
                line-height: 42px;
                margin-bottom: 18px;
                font-weight: 700;
            }

            > .description {
                font-size: 18px;
                line-height: 32px;
                font-weight: 500;
            }
        }

        > .images {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 80px;
            padding-right: 115px;

            > .silver-horse {
                object-fit: cover;
                width: 431px;
                z-index: 2;

                > img {
                    width: 100%;
                }
            }

            > .arrow {
                margin-right: 34px;
                z-index: 3;
            }

            > .bronze-horse {
                object-fit: cover;
                width: 260px;
                z-index: 1;
                position: relative;

                > img {
                    width: 100%;
                }

                &::before {
                    background-image: url(../images/light.webp);
                    position: absolute;
                    content: '';
                    height: 500px;
                    width: 500px;
                    background-size: cover;
                    z-index: -1;
                    top: -120px;
                    left: -120px;
                }

                &::after {
                    background: rgba(71, 100, 176, 1);
                    border-radius: 50%;
                    position: absolute;
                    content: '';
                    height: 220px;
                    width: 424px;
                    background-size: cover;
                    z-index: -2;
                    top: 14px;
                    left: -80px;
                    filter: blur(150px);
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .nft-craft-block {
        > .container {
            padding: 54px 0 105px ;
            margin-bottom: 63px;

            > .text {
                > .description {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            > .images {
                margin-top: 60px;
                padding-right: 75px;

                > .silver-horse {
                    width: 342px;
                }

                > .arrow {
                    margin-right: 20px;

                    > svg {
                        width: 96px;
                        height: 27px;
                    }
                }

                > .bronze-horse {
                    width: 206px;

                    &::before {
                        width: 397px;
                        height: 397px;
                        top: -99px;
                        left: -97px;
                        margin-bottom: 59px;
                        background-size: cover;
                        z-index: -2;
                        top: 14px;
                        left: -80px;
                        filter: blur(150px);
                    }

                    &::after {
                        height: 174px;
                        width: 337px;
                        top: 14px;
                        left: -80px;
                        filter: blur(119px);
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .nft-craft-block {
        > .container {
            padding: 33px 0 94px ;
            margin-bottom: 53px;

            > .text {
                margin-bottom: 38px;

                > .title {
                    font-size: 28px;
                    line-height: 42px;
                    margin-bottom: 10px;
                }

                > .description {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            > .images {
                margin-top: 0px;
                padding-right: 35px;

                > .silver-horse {
                    width: 250px;
                }

                > .arrow {
                    > svg {
                        width: 70px;
                        height: 20px;
                    }
                }

                > .bronze-horse {
                    width: 150px;

                    &::before {
                        width: 290px;
                        height: 290px;
                        top: -84px;
                        left: -68px;
                    }

                    &::after {
                        height: 246px;
                        width: 127px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .nft-craft-block {
        padding: 0 15px;
        margin-bottom: 45px;

        > .container {
            padding: 26px 13px 33px;
            border-radius: 20px;

            > .text {
                margin-bottom: 0px;

                > .title {
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 33px;
                }

                > .description {
                    font-size: 12px;
                    line-height: 20px;
                }

                > .images {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 0px;
                    padding-right: 28px;
                    margin-bottom: 46px;

                    > .silver-horse {
                        object-fit: cover;
                        width: 135px;
                        z-index: 2;

                        > img {
                            width: 100%;
                        }
                    }

                    > .arrow {
                        z-index: 3;
                        margin-bottom: 12px;
                        margin-left: -14px;

                        > svg {
                            width: 32px;
                            height: 10px;
                            height: auto;
                        }
                    }

                    > .bronze-horse {
                        object-fit: cover;
                        width: 81px;
                        z-index: 1;
                        position: relative;

                        > img {
                            width: 100%;
                        }

                        &::before {
                            background-image: url(../images/light.webp);
                            position: absolute;
                            content: '';
                            height: 156px;
                            width: 156px;
                            background-size: cover;
                            z-index: -1;
                            top: -44px;
                            left: -38px;
                        }

                        &::after {
                            background: rgba(71, 100, 176, 1);
                            border-radius: 50%;
                            position: absolute;
                            content: '';
                            height: 68px;
                            width: 132px;
                            background-size: cover;
                            z-index: -2;
                            top: 4px;
                            left: -23px;
                            filter: blur(48px);
                        }
                    }
                }
            }
        }
    }
}
</style>
