<template>
    <div class="status-cards-holder-block">
        <div class="container">
            <div class="title">
                {{ $i18n.get(`Status Cards Holder Game`) }}
            </div>

            <div v-if="$store.state.isMobile" class="image">
                <img :src="require('../images/cup.webp')" alt="">
            </div>

            <div class="description" v-if="$store.state.isMobile" v-html="$i18n.get(`Status card owners will take part in a special game with Jackpot and other prizes. The higher the status, the higher the chance to win.`)"></div>

            <div class="description" v-else v-html="$i18n.get(`Status card owners will take part in a special game with Jackpot and other prizes.<br>The higher the status, the higher the chance to win.`)"></div>

            <div v-if="!$store.state.isMobile" class="image">
                <img :src="require('../images/cup.webp')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'status-cards-holder-block'
}
</script>

<style lang="less">
.status-cards-holder-block {
    > .container {
        width: var(--main-width-full);
        margin: auto;
        margin-bottom: 139px;
        border-radius: 50px;
        background: rgba(13, 37, 93, 1);
        padding: 55px 80px 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        overflow: hidden;

        > .title {
            font-weight: 700;
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 18px;
        }

        > .description {
            font-size: 18px;
            line-height: 32px;
        }

        > .image {
            object-fit: cover;
            width: 100%;
            margin-top: -60px;

            > img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 1279px) {
    .status-cards-holder-block {
        > .container {
            padding: 55px 30px 35px;
            margin-bottom: 101px;

            > .description {
                font-size: 16px;
                line-height: 28px;
            }

            > .image {
                margin-top: -50px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .status-cards-holder-block {
        > .container {
            padding: 45px 30px 35px;
            margin-bottom: 101px;

            > .title {
                font-size: 28px;
                line-height: 42px;
                margin-bottom: 10px;
            }

            > .description {
                font-size: 14px;
                line-height: 25px;
            }

            > .image {
                margin-top: -50px;
            }
        }
    }
}

@media (max-width: 767px) {
    .status-cards-holder-block {
        padding: 0 15px 0;
        > .container {
            padding: 25px 15px 35px;
            margin-bottom: 45px;

            > .title {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 0px;
            }

            > .description {
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
            }

            > .image {
                margin: -10px -45px 10px;
                width: auto;
            }
        }
    }
}
</style>