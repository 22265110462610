<template>
    <div class="allocations-block">
        <div class="container">
            <div class="content">
                <h2 class="title">
                    {{ $i18n.get(`PRE-IDO allocations 🚀`) }}
                </h2>

                <div class="text">
                    <p class="paragraph" v-if="screenWidth > 1023" v-html="$i18n.get(`Holders of Gold status and above receive an allocation to pre-IDO of TRUE.<br>The higher the status, the greater allocation is available.<br>The IDO is planned soon.`)"></p>
                    <p class="paragraph" v-else v-html="$i18n.get(`Holders of Gold status and above receive an allocation to pre-IDO of TRUE. The higher the status, the greater allocation is available. The IDO is planned soon.`)"></p>
                </div>

                <a class="button" :href="$env.IDO_HOST" target="_blank">
                    IDO
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.954045 8.48523C0.661152 8.77813 0.661152 9.253 0.954045 9.54589C1.24694 9.83879 1.72181 9.83879 2.01471 9.54589L0.954045 8.48523ZM10.2655 0.984436C10.2655 0.570222 9.92972 0.234436 9.5155 0.234436L2.7655 0.234435C2.35129 0.234435 2.0155 0.570222 2.0155 0.984436C2.0155 1.39865 2.35129 1.73444 2.7655 1.73444H8.7655V7.73444C8.7655 8.14865 9.10129 8.48444 9.5155 8.48444C9.92972 8.48444 10.2655 8.14865 10.2655 7.73444L10.2655 0.984436ZM2.01471 9.54589L10.0458 1.51477L8.98517 0.454106L0.954045 8.48523L2.01471 9.54589Z" fill="white"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'allocations-block',
    data() {
        return {
            screenWidth: 0,
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    }
}
</script>

<style lang="less">
.allocations-block {
    margin-bottom: 17px;
    padding: 48px 0 30px;
    background: url("../images/wave_background.webp") no-repeat top;
    background-size: cover;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .content {
            padding: 104px 165px;
            background: url(../images/blur-bg-img.webp);
            backdrop-filter: blur(80px);
            border-radius: 50px;

            > .title {
                margin-bottom: 12px;
                font-weight: 700;
                font-size: 36px;
                line-height: 42px;
                text-align: center;
            }

            > .text {
                margin-bottom: 26px;

                > .paragraph {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 34px;
                    text-align: center;
                }
            }

            > .button {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                max-width: 180px;
                margin: 0 auto;
                padding: 18px 66px;
                background: var(--promo-gradient);
                border-radius: 100px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--white);
            }
        }

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        > .content {
                background: var(--white-10);
                backdrop-filter: blur(80px);
            }
        }
    }
}

@media (max-width: 1279px) {
    .allocations-block {
        margin-bottom: 6px;

        > .container {
            > .content {
                padding: 110px 80px;

                > .text {
                    > .paragraph {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .allocations-block {
        margin-bottom: 15px;

        > .container {
            > .content {
                > .title {
                    margin-bottom: 5px;
                    font-size: 28px;
                    line-height: 42px;
                }

                > .text {
                    > .paragraph {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .allocations-block {
        margin-bottom: 11px;
        padding: 38px 15px 37px;

        > .container {
            > .content {
                padding: 27px 15px 34px;

                > .title {
                    margin-bottom: 6px;
                    font-size: 18px;
                    line-height: 27px;
                }

                > .text {
                    > .paragraph {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                > .button {
                    column-gap: 6px;
                    max-width: 140px;
                    padding: 10px 51px;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--white);
                }
            }
        }
    }
}
</style>
