<template>
    <div class="offer-statuses-block">
      <div class="offer">
        <div class="container">
          <div class="crown">
            <img :src="require('../images/crown-image.png')">
          </div>
          <h2 class="title">
              <img :src="require('../images/title-image.png')" :alt="$i18n.get(`TRUE Status cards`)">
          </h2>

          <div class="text" v-html="$i18n.get(`Provide access to exclusive features, utilities, pre-sales and NFT drops on our platform.`)"></div>

          <div class="buttons">
              <w-button type="promo" class="scroll-click">
                  {{ $i18n.get(`Explore`) }}
              </w-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'offer-block',
    data() {
        return {
            screenWidth: 0,
            partners: [
                {
                  image: 'polygon.svg',
                  status: 'active',
                },
                {
                    image: 'ethereum.svg',
                    status: 'active',
                },
                {
                    image: 'bnb_chain.svg',
                    status: 'soon',
                },
                {
                    image: 'avalanche.svg',
                    status: 'soon',
                },
                {
                    image: 'near.svg',
                    status: 'soon',
                },
                {
                    image: 'solana.svg',
                    status: 'soon',
                },
            ],
        }
    },
}
</script>

<style lang="less">
.offer-statuses-block {
  background: #031337;
  > .offer {
    padding: 66px 0 165px 0;
    background: url("../images/bg-offer.png") no-repeat top;
    background-size: auto 940px;

    > .container {
      width: var(--main-width-full);
      background: url("../images/cards-image.png") no-repeat top;
      background-size: 1248px;
      background-position: center 32px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      > .crown {
        > img {
          display: block;
          width: 160px;
        }
        margin-bottom: 123px;
      }

      > .title {
        margin-bottom: 28px;
        > img {
          display: block;
          width: 784px;
        }
      }

      > .text {
          max-width: 840px;
          margin: 0 auto 50px;
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
          text-align: center;
      }

      > .buttons {
          display: flex;
          justify-content: center;
          column-gap: 10px;
        > .btn {
          background: linear-gradient(78.22deg, #3B9BF4 0.65%, #FF5FFF 76.67%);
          width: 280px;
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          &:hover {
            background: linear-gradient(78.22deg, #62aff6 0.65%, #ff7fff 76.67%);
          }
        }
      }
    }
  }

  > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .partners {
            display: flex;
            justify-content: center;
            column-gap: 10px;

            > .logo {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px;
                height: 70px;
                border-radius: 20px;
                background: rgba(255, 255, 255, 0.08);

                &.-soon {
                    background: rgba(255, 255, 255, 0.04);

                    &:after {
                        content: 'soon';
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: block;
                        padding: 4px 13px;
                        border-radius: 0 30px 0 20px;
                        background: rgba(255, 255, 255, 0.05);
                        font-weight: 600;
                        font-size: 8px;
                        line-height: 12px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        color: var(--white-40);
                    }

                    img {
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

@media (max-width: 1439px) {
  .offer-statuses-block {
    > .offer {
      padding-top: 68px;
      padding-bottom: 122px;
      background-size: auto 780px;
      > .container {
        background-size: 936px;
        > .crown {
          margin-bottom: 100px;
          > img {
            width: 120px;
          }
        }
        > .title {
          margin-bottom: 14px;
          > img {
            width: 660px;
          }
        }
        > .text {
          margin-bottom: 36px;
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .offer-statuses-block {
    > .offer {
      padding-top: 75px;
      padding-bottom: 112px;
      background-size: auto 668px;
      > .container {
        background-size: 748px;
        > .crown {
          margin-bottom: 77px;
          > img {
            width: 100px;
          }
        }
        > .title {
          margin-bottom: 12px;
          > img {
            width: 570px;
          }
        }
        > .text {
          margin-bottom: 24px;
          font-size: 20px;
          line-height: 32px;
        }
        > .buttons {
          > .btn {
            display: flex;
            height: 50px;
            font-size: 14px;
            line-height: 21px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .offer-statuses-block {
    > .offer {
      background-size: auto 530px;
      padding: 0;
      > .container {
        background-size: 430px;
        padding: 40px 40px 70px;
        background-position: center 104px;
        > .crown {
          margin-bottom: 64px;
          > img {
            width: 100px;
          }
        }
        > .title {
          margin-bottom: 18px;
          > img {
            width: 350px;
          }
        }
        > .text {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 24px;
        }
        > .buttons {
          width: 100%;
          > .btn {
            display: flex;
            width: 100%;
            height: 50px;
            font-size: 14px;
            line-height: 21px;
            padding: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 429px) {
  .offer-statuses-block {
    > .offer {
      background-size: auto 530px;
      padding: 0;
      > .container {
        background-size: 320px;
        padding: 20px 30px 50px;
        background-position: center 66px;
        > .crown {
          margin-bottom: 44px;
          > img {
            width: 75px;
          }
        }
        > .title {
          margin-bottom: 16px;
          > img {
            width: 280px;
          }
        }
        > .text {
          margin-bottom: 22px;
          font-size: 14px;
          line-height: 21px;
        }
        > .buttons {
          width: 100%;
          > .btn {
            display: flex;
            width: 100%;
            height: 40px;
            font-size: 14px;
            line-height: 21px;
            padding: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
