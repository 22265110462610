<template>
    <div class="multi-chain-block">
        <div class="container">
            <div class="text">
                <h2 class="title">
                    {{ $i18n.get(`Multi-chain NFTs`) }}
                </h2>

                <p class="description">
                    {{ $i18n.get(`Our NFTs will be released in Ethereum and Polygon networks. You can choose the network you wish to issue your NFT at. Other networks will be implemented very soon. In future, you will be able to transfer your NFTs from one network to another using the cross-chain bridge.`) }}
                </p>
            </div>

            <img class="image" :src="require(`../images/multi_chain.webp`)" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'multi-chain-block',
}
</script>

<style lang="less">
.multi-chain-block {
    margin-bottom: 42px;

    > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: var(--main-width-full);
        margin: 0 auto;

        > .text {
            max-width: 495px;

            > .title {
                margin-bottom: 18px;
                font-weight: 700;
                font-size: 36px;
                line-height: 42px;
            }

            > .description {
                font-weight: 500;
                font-size: 18px;
                line-height: 32px;
            }
        }

        > .image {
            max-width: 670px;
        }
    }
}

@media (max-width: 1279px) {
    .multi-chain-block {
        margin-bottom: 60px;

        > .container {
            > .text {
                max-width: 410px;

                > .description {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            > .image {
                max-width: 530px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .multi-chain-block {
        margin-bottom: 48px;

        > .container {
            > .text {
                max-width: 334px;

                > .title {
                    margin-bottom: 10px;
                    font-size: 28px;
                    line-height: 42px;
                }

                > .description {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            > .image {
                max-width: 393px;
            }
        }
    }
}

@media (max-width: 767px) {
    .multi-chain-block {
        margin-bottom: 45px;

        > .container {
            position: relative;
            padding: 0 30px;

            > .text {
                max-width: 100%;

                > .title {
                    margin-bottom: 197px;
                    font-size: 18px;
                    line-height: 27px;
                }

                > .description {
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            > .image {
                position: absolute;
                top: 0;
                left: calc(50% - 300px/2);
                max-width: 300px;
            }
        }
    }
}
</style>