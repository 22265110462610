import { render, staticRenderFns } from "./GetStatusCards.vue?vue&type=template&id=4cb82a8b&"
import script from "./GetStatusCards.vue?vue&type=script&lang=js&"
export * from "./GetStatusCards.vue?vue&type=script&lang=js&"
import style0 from "./GetStatusCards.vue?vue&type=style&index=0&id=4cb82a8b&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports